.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1500;
  }
  
  .overlay.visible {
    display: flex;
  }
  