.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.nopadding td,
.borderless th {
  padding: 0;
}

.header-padding {
  padding-bottom: 93px;
}

.no-border {
  border: 0px;
}

.sign-out {
  text-align: center !important;
}

.login-menu {
  min-width: 100% !important;
}

.bg-gray {
  background-color: #8a8a8a;
}

.bg-lightgray {
  background-color: #f0f0f5;

}

.color-black {
  color: #000000
}

input:disabled {
  opacity: 1;
}

:root {
  --bs-border-color: black !important;
}

.aspirant-options {
  margin-top: 2rem;
  margin-bottom: 10rem;
  padding: 9rem;
  border-radius: 100px;
}

.aspirant-input {
  width: 45%;
  min-width: 100px;
  max-width: 350px;
}

.posso-right {
  float: right !important;
  clear: both !important;
}

.aspirant-profile-chat {
  margin-right: 75% !important;
}

.text-black {
  color: black !important;
}

.organization-add-job {
  border: 0;
}

.aspirant-interview-link:hover {
  background-color: white;
}

.aspirant-interview-link:active {
  background-color: white;
}

.interview-how-button {
  border: none;
  background-color: transparent;
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.15em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
  --bs-spinner-vertical-align: -0.2em;
  margin-right: 0.25rem;

}

.navbar {
  box-shadow: 0px 1.9px 2.2px rgba(0, 0, 0, 0.02), 0px 4.5px 5.3px rgba(0, 0, 0, 0.05);
  border-bottom: none !important;
}

/* .link-primary:hover {
  color: #7300E6 !important
}

.link-primary:active {
  color: #7300E6 !important
} */

.job-info-divider {
  border-color: #000000 !important;
  border: 0;
  font-size: 20px;
  font-weight: 600;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: center;
  color: black;
}

.job-info-divider::before {
  content: "";
  background: black;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.job-info-divider::after {
  color: black;
  background-color: white;
  content: attr(data-content);
  line-height: 1.5em;
  padding: 0 7px;
  position: relative;
}

.fb_selected {
  background-color: #7300E6 !important;
  color: white !important;
}


.row {
  /* margin-left: auto !important; */
}

label[for='ui-sign-in-email-input'] {
  display: none;
}

.interview-skills .accordion-item,
.job-description .accordion-item {
  border: 0 !important;
}

.job-description .accordion-button {
  background-color: #fff;
  color: #000000;
  box-shadow: none;
  margin: 0rem;
}

.job-description .accordion-button:not(.collapsed) {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  .collapse:not(.show) {
    display: none;
  }
}

.line-divide {
  border-color: #000000;
  margin-top: 2px;
  margin-bottom: 2px;
}

.header-divide {
  border-color: #000000;
  margin-top: 3px;
  margin-bottom: 3px;
  border-width: 2px;
}

.seed-question-links {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #0086ec;
  text-decoration: underline;
  cursor: pointer;
}

.seed-question-links:hover {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #006fc4;
  text-decoration: underline;
  cursor: pointer;
}

.seed-question-links:active {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #005da4;
  text-decoration: underline;
  cursor: pointer;
}

.PhoneInputCountry {
  border: var(--bs-border-width) solid #999999;
  margin-right: 0px !important;
  border-radius: .375rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0;
  padding-left: .5rem;
  padding-right: .25rem;
}


.PhoneInputInput {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: .375rem .75rem;
  font-size: 0.9rem;
  border-color: #999999;
  font-weight: 600;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #999999;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.PhoneInput {
  max-width: 300px;
}

.signup-form .PhoneInputInput,
.contact-us .PhoneInputInput {
  font-size: 1rem;
  font-weight: 400;
  max-width: 400px;
}

.signup-form .PhoneInput,
.contact-us .PhoneInput {
  max-width: 400px;
}

.signup-form .form-control,
.contact-us .form-control {
  border: var(--bs-border-width) solid #999999;
}

.quickinterviewinput {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: .375rem .75rem;
  font-size: 0.9rem;
  border-color: #999999;
  font-weight: 600;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #999999;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.quickinterviewinput::placeholder {
  font-weight: 600;
  font-size: 0.9rem;
}

.interview-report-row {
  margin-left: calc(-.5 * var(--bs-gutter-x)) !important;
}

.interview-report-h2 {
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}

.interview-report-detail {
  padding-bottom: 1px !important;
}

.list-display li {
  margin-bottom: .4rem;
  /* font-size:1.6rem; */
}

.list-checkmarks {
  padding-left: 2rem;
}

.list-checkmarks li {
  list-style-type: none;
  padding-left: 1rem;
}

.list-checkmarks li:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  margin: 0 10px 0 -28px;
  /* color: #ffffff; */
}

@media (max-width: 768px) {
  .imgintpage {
    max-width: 75%;
  }
}

.grid-header {
  font-size: calc(1.275rem + .3vw);
  font-weight: bold;
  line-height: 1;
  color: #000000
}

@media (min-width: 1200px) {
  .grid-header {
    font-size: 1.5rem;
  }
}

.add-job-candidate {
  padding: 0;
  line-height: 1;
}

.add-job-candidate::after {
  display: none;
}

.add-job-candidate:hover {
  background-color: #fff !important;
  color: #fff !important;
  border-color: #fff !important;
}

.top-snackbar {
  top: 93px !important;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in {
  animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.slide-out {
  animation: 0.5s ease-out 0s 1 slideOutToLeft;
}

.z-1501 {
  z-index: 1501 !important;
}

.modal-title {
  line-height: 1;
}

.status-message-container {
  min-height: 24px;
}

.snackbar-btn {
  padding-right: .25rem !important;
  padding-left: .6rem !important;
  line-height: unset !important;
  min-height: unset !important;
}

.mw-300px {
  max-width: 300px !important;
}

.skill-badge {
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 992px) {
  .mobile-divider {
    margin: 8px 0;
    /* Adjust margin as needed */
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    /* Add your preferred divider style */
    opacity: 1;
  }
}

.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
  padding: 1em;
  border-width: 1px;
  border-color: #CEDBE0;
  border-style: solid;
  border-radius: 0.5rem;
  z-index: 1000;
}

.speech-bubble::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -31px;
  width: 0;
  height: 0;
  border-left: none;
  border-top: 31px solid transparent;
  border-bottom: 31px solid transparent;
  border-left: 46px solid #CEDBE0;
}

.speech-bubble::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -30px;
  width: 0;
  height: 0;
  border-left: none;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 45px solid #ffffff;
}

.responsive-image {
  max-width: 100%;
  max-height: 100%;
  padding: 2px;
  object-fit: contain;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reactmic-style {
  width: 100%;
  height: 10vh;
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: transparent !important;
}

.acs-contolbar {
  background-color: transparent !important;
}

.acs-controlbar-icon {
  width: 70% !important;
}

card-hover {
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  cursor: pointer !important;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer !important;
}

.endinterview-btn {
  background-color: #FA695A !important;
  color: white !important;
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 1rem !important;
}

@media (max-width: 768px) {
  .endinterview-btn {
    background-color: transparent !important;
    color: #000000 !important;
    border: none !important;
    padding: 0.5rem 1rem !important;
  }
}

.page-break {
  page-break-before: always;
}

.range-slider {
  width: 75% !important;
}

@media (max-width: 992px) {
  .range-slider {
    width: 75% !important;
  }
}

.icebreaker-dropdown {
  width: auto;
  display: inline-block;
}

.coding-question-card {
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  word-wrap: break-word;
  min-height: 80vh;
  border: #000000;
}

.coding-quuestion-card-header {
  display: flex;
  width: 100%;
  background-color: #F6F6F6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 42px;
  vertical-align: center;
  align-items: center;
  padding-left: .5rem;
  font-size: 1rem;
  font-weight: bold;
}

.coding-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.coding-editor-width {
  width: calc(66.66666667% - .5rem);
}

.coding-language-dropdown {
  border: none;
  /* background-color: transparent; */
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-weight: bold;
}

.coding-inst-language-dropdown {
  /* background-color: transparent; */
  border-radius: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: bold;
}

.coding-language-dropdown:focus {
  outline: none;
  border: none;
}

.posso-ace-editor {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

code {
  font-weight: bold;
  color: #000000;
  font-size: large;
  font-style: italic;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px !important;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.form_blur {
  filter: blur(4px);
}