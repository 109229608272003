#homepagev2 {
    font-family: 'HelveticaNowDisplay';
    font-weight: normal;
    color: #000;
    line-height: 1.4;
    padding-top: 0;
    background: #fff;
    font-size: 1rem;
    font-weight: 400;
}

#homepagev2 a {
    color: #7300E6;
    text-decoration: none;
}

#homepagev2 img {
    max-width: 100%;
}


/*title*/
#homepagev2 h1 {
    font-size: 5rem;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;
}

#homepagev2 h2 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0 0 50px 0;
    line-height: 1.2;
}

#homepagev2 h3 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: 0 0 25px 0;
    line-height: 1.2;
}

#homepagev2 h4 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 25px 0;
    line-height: 1.1;
}

#homepagev2 h5 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 5px 0;
}

#homepagev2 h6 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 15px 0;
}

/*title*/

#homepagev2 .text-purple {
    color: #7300e6;
}


#homepagev2 .fw-300 {
    font-weight: 300 !important;
}

#homepagev2 .fw-500 {
    font-weight: 500 !important;
}

#homepagev2 .fw-600 {
    font-weight: 600 !important;
}


#homepagev2 .fw-800 {
    font-weight: 800 !important;
}



#homepagev2 .container {
    max-width: 1210px;
    position: relative;
    z-index: 2;
}


/*btn*/
#homepagev2 .btn {
    border-radius: 5px;
    padding: 13px 30px;
    font-weight: 400;
    display: inline-block;
    min-width: 130px;
    font-size: 1rem
}

#homepagev2 .btn-primary {
    background: #000;
    border: 0;
    color: #fff;
}

#homepagev2 .btn-primary:hover {
    /* background-image: linear-gradient(60deg,#ff3a7c,#741eff); */
    background: #fff;
}

#homepagev2 .btn-secondary {
    background: #ffaa00;
    border: 0;
    color: #000;
}

#homepagev2 .btn-secondary:hover {
    background: rgb(255 170 0 / 90%);
    border: 0;
    color: #000;
}

#homepagev2 .btn-outline-primary {
    border-color: #fff;
    color: #fff;
}

#homepagev2 .btn-outline-primary:hover {
    border-color: rgb(255 255 255 / 90%);
    color: #fff;
}

/*btn*/

#homepagev2 .form-control {
    border-radius: 5px;
    height: 50px;
    min-width: 300px;
}

#homepagev2 .contact-us .form-control {
    height: 40px;
}

#homepagev2 .contact-us .btn {
    line-height: 1.2rem;
}

/*global*/
#homepagev2 .text-gr {
    background: -webkit-linear-gradient(180deg, #6600ff, #dc11fd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#homepagev2 .section {
    padding-top: 150px;
    padding-bottom: 150px;
    min-height: calc(100vh);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

#homepagev2 .section-2 {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

#homepagev2 .section-3 {
    padding-top: 90px;
    padding-bottom: 90px;
}

/*global*/


/*header*/
#homepagev2 .header-section {
    padding: 15px 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 6;
    /* background-color: #000; */
    backdrop-filter: blur(5px);
}

#homepagev2 .nav-link {
    color: #000;
    margin-left: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem
}

#homepagev2 .nav-link:hover {
    color: #7300E6;
}

#homepagev2 .help-text {
    display: block;
    font-size: 70%;
    opacity: 0.5;
}

#homepagev2 .navbar-toggler {
    display: none;
    font-size: 0.8rem;
}

/*header*/


/*footer*/
#homepagev2 footer {
    padding: 30px 0 30px 0;
    background: #e0f0f0;
    /* color: #fff; */
    position: relative;
    z-index: 3;
}

#homepagev2 footer .f-logo {}

#homepagev2 footer .form-control {
    background-color: rgb(255 255 255 / 25%);
    border: 0;
    color: #fff;
    height: 37px;
}

#homepagev2 footer .form-control:hover,
#homepagev2 footer .form-control:focus {
    background-color: rgb(255 255 255 / 35%);
    color: #fff;
}

#homepagev2 footer .form-control::placeholder {
    color: #fff;
    opacity: 1;
}

#homepagev2 footer .form-control::-ms-input-placeholder {
    color: #fff;
}

#homepagev2 footer .social-links {}

#homepagev2 footer .social-links i {}

#homepagev2 footer .social-links a:hover i {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    -webkit-background-clip: text;
    background-clip: text;
}

#homepagev2 footer .social-links a {
    color: #000;
    font-size: 1.5rem;
    display: inline-block;
    margin: 0 6px;
}

#homepagev2 footer .social-links a .fa-facebook {
    font-size: 89%;
}

#homepagev2 footer .copy {}

#homepagev2 footer h3 {
    font-size: 50px;
    margin: 5px 0 40px 0;
}

#homepagev2 .f-bg {
    position: relative;
    z-index: 3;
}

#homepagev2 .f-bg img {
    min-width: 100%;
}

/*footer*/


/*banner*/
#homepagev2 .banner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background: url(../../public/assets/home-page-v2-images/bg-white.png) no-repeat center center;
    background-size: 100% 100%;
    padding: 90px 0 90px 0;
}

#homepagev2 .banner h1 {}

#homepagev2 .banner h2 {
    /* font-size: 3rem; */
}

#homepagev2 .banner h3 {}

#homepagev2 .btn {
    transition: all 1s ease-out;
    position: relative;
    overflow: hidden;
}

#homepagev2 .btn-primary {
    background-color: #000000;
    border: solid 0 #000;
}

#homepagev2 .btn-primary:hover {
    /* background-image: linear-gradient(60deg,#ff3a7c,#741eff); */
    /* border-color:#fff; */
    /* color:#fff; */
    background: #000;
}

#homepagev2 .btn-primary:after {
    content: "";
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    width: 104%;
    height: 104%;
    position: absolute;
    left: 100%;
    top: -2%;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}

#homepagev2 .btn-primary:hover:after {
    left: -2%;
}

#homepagev2 .btn-primary span {
    position: relative;
    z-index: 2;
}

#homepagev2 .btn-primary:hover {}

/*banner*/

/*video section */
#homepagev2 .video-section {
    padding: 0;
    height: calc(100vh);
    overflow: hidden;
    position: relative;
}

#homepagev2 .video-section h3 {
    position: absolute;
    width: 100%;
    left: 0%;
    top: 15%;
    margin: -26px 0 0 0;
    text-align: center;
}

#homepagev2 .video-section video {
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
}

/*video section */

/*features*/
#homepagev2 .features {
    position: relative;
}

#homepagev2 .features:before {
    background-image: url(../../public/assets/home-page-v2-images//bg-1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    display: block;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    width: 30%;
}

/*features*/


#homepagev2 .video-bg {
    position: fixed;
    left: 0;
    top: -64%;
    width: 100%;
    z-index: 1;
    opacity: 0.5;
}

#homepagev2 .video-icon {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    left: -70px;
}


/*evaluate*/
#homepagev2 .evaluate .check-list-1 {
    max-width: 690px;
    list-style: none;
    margin: 0 auto;
    padding: 20px 0;
}

#homepagev2 .evaluate .check-list-1 li {
    padding: 0 0 12px 0;
    padding-left: 45px;
    font-size: 30px;
    background: url(../../public/assets/home-page-v2-images/tick-icon-white.svg) no-repeat 0 5px;
    background-size: 31px auto;
    margin: 0 0 5px 0;
}

#homepagev2 .evaluate .box {
    margin: 0 0 30px 0px;
    border-radius: 15px;
    background: #fff;
    padding: 30px 30px;
    padding-right: 10px;
    padding-left: 10px;
    min-width: 100%;
    transition: 0.3s ease-in-out;
}

@media (max-width: 768px) {
    #homepagev2 .evaluate .box {
        text-align: -webkit-center;
    }
}


#homepagev2 .evaluate .box:hover {
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
}

#homepagev2 .evaluate .box h5 {
    font-size: 1.5rem;
}

#homepagev2 .evaluate .img-fluid {
    margin: 0 0 15px 0;
    width: 70px;
}

#homepagev2 .evaluate .img-fluid img {
    width: 50px;
}

#homepagev2 .evaluate .box h4 {
    margin: 0 0 10px 0;
}

#homepagev2 .evaluate .box p {
    margin: 0;
}

#homepagev2 .evaluate .row {}

@media (min-width: 992px) {
    #homepagev2 .evaluate .row .col-md-6 {
        display: flex;
        padding: 0 15px;
    }

    #homepagev2 .evaluate .box,
    #homepagev2 .evaluate .cont {
        display: flex;
    }

    #homepagev2 .evaluate .box {
        padding: 50px 30px;
    }
}

/*evaluate*/


/*scale*/

#homepagev2 .scale {}

#homepagev2 .scale:before {
    background-image: url(../../public/assets/home-page-v2-images//bg-2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom center;
    content: "";
    display: block;
    height: 30%;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    opacity: 0;
}


#homepagev2 .scale .box {
    border-radius: 14px;
    background: #fff;
    padding: 35px;
    padding-bottom: 40px;
    min-height: 460px;
}

#homepagev2 .scale .img-fluid {
    margin-bottom: 25px;
}

#homepagev2 .scale .img-fluid img {
    width: 50px;
}

#homepagev2 .scale .box h4 {
    font-size: 1.5rem;
}

#homepagev2 .scale .box ul {
    margin: 0;
    padding: 0 0 0 13px;
}

#homepagev2 .scale .box ul li {
    margin: 0 0 10px 0;
}

#homepagev2 .scale .row {}

#homepagev2 .scale .cont {
    background: #f4f4f6;
    padding: 2px;
    padding-top: 1.5px;
    border-radius: 16px;
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    margin: 15px 0;
    transition: 0.3s ease-in-out;
}

@media (min-width: 992px) {
    #homepagev2 .scale .cont:hover {
        box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
    }

    #homepagev2 .scale .cont {
        display: flex;
        margin: 0 15px;
    }

    #homepagev2 .scale .cont .cont-box {
        display: flex;
    }

    #homepagev2 .scale .columns-2 .cont {
        width: calc(50% - 30px);
    }

    #homepagev2 .scale .columns-3 .cont {
        width: calc(33.333% - 30px);
    }

    #homepagev2 .scale .columns-4 .cont {
        width: calc(25% - 30px);
    }
}

/*scale*/


/*pricing*/
#homepagev2 .pricing {
    padding: 0;
    position: relative;
    margin: 0 auto;
    color: #000;
    text-align: center;
    max-width: 900px;
}

#homepagev2 .pricing h4 {
    margin: 0 0 1rem 0 !important;
}

#homepagev2 .pricing h3 {}

#homepagev2 .pricing .icon {
    max-width: 50px;
    margin: 0 auto 15px auto;
    position: relative;
    z-index: 1000;
}

#homepagev2 .pricing .row {
    position: relative;
    margin-bottom: 2cm;
    z-index: 3;
    position: relative;
}

#homepagev2 .pricing .row .col-sm-6 {
    padding: 50px 40px;
    position: static;
    margin: 0 0.5cm;
    border-radius: 15px;
    position: relative;
}

#homepagev2 .pricing .row .col-sm-6:nth-child(1) {}

#homepagev2 .pricing .row .col-sm-6:nth-child(2) {}

#homepagev2 .pricing .row:last-child {
    margin-bottom: 0;
}

#homepagev2 .pricing p {
    margin: 0;
}

#homepagev2 .svg path,
#homepagev2 .svg rect,
#homepagev2 .svg polygon {
    fill: rgb(0 0 0);
}


#homepagev2 .pricing .row .col-sm-6:nth-child(2) h3,
#homepagev2 .pricing .row .col-sm-6:nth-child(2) h4,
#homepagev2 .pricing .row .col-sm-6:nth-child(2) p {
    color: #fff;
}

#homepagev2 .pricing .row .col-sm-6:nth-child(1) {
    background: #c9afdf;
    background: linear-gradient(180deg, rgb(201 175 223 / 30%) 0%, rgb(212 204 221 / 30%) 100%);
}

#homepagev2 .pricing .row .col-sm-6:nth-child(2) {
    background: #532e77;
    background: linear-gradient(180deg, #532e77 0%, #532e77 100%);
}

#homepagev2 .pricing .row .col-sm-6:nth-child(2) .svg path,
#homepagev2 .pricing .row .col-sm-6:nth-child(2).svg rect,
#homepagev2 .pricing .row .col-sm-6:nth-child(2) .svg polygon {
    /* fill: #000000; */
}

/* #homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(1) {background-color: #5762d5;}
#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(2) {background-color: #97dffc;}


#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(1) {background-color: #9f82ff;}
#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(2) {background-color: #29195b;}

#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(1) {background-color: #242038;}
#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(2) {background-color: #cac4ce;}


#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(1) .svg path, 
#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(1).svg rect,
#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(1) .svg polygon { }

#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(2) .svg path, 
#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(2).svg rect,
#homepagev2 .pricing .row:nth-child(1) .col-sm-6:nth-child(2) .svg polygon {fill: #000000;}


#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(1) .svg path, 
#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(1).svg rect,
#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(1) .svg polygon {fill: #000000;}

#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(2) .svg path, 
#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(2).svg rect,
#homepagev2 .pricing .row:nth-child(2) .col-sm-6:nth-child(2) .svg polygon { }


#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(1) .svg path, 
#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(1).svg rect,
#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(1) .svg polygon { }

#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(2) .svg path, 
#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(2).svg rect,
#homepagev2 .pricing .row:nth-child(3) .col-sm-6:nth-child(2) .svg polygon {fill: #000000;}*/

#homepagev2 .svg {
    width: 50px;
    height: 31px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -68px;
    display: none;
}

#homepagev2 .pricing .row .col-sm-6:nth-child(2) .svg {
    margin-left: 19px;
    transform: rotate(180deg);
}

#homepagev2 .before-after {
    text-align: center;
    margin: 0 auto 26px auto;
    font-weight: 500;
    max-width: 900px;
    font-size: 1.5rem;
    text-transform: uppercase;
}

@media (min-width: 576px) {
    #homepagev2 .pricing .row {
        display: flex;
        align-items: stretch;
    }

    #homepagev2 .pricing .row .col-sm-6 {
        width: calc(50% - 1cm);
    }
}

@media (max-width: 576px) {
    #homepagev2 .pricing .row {
        margin-bottom: 0px;
    }

    #homepagev2 .pricing .row .col-sm-6:nth-child(1) {
        padding-right: 40px;
        padding-top: 70px;
        margin: 0.5cm auto;
        position: relative;
    }

    #homepagev2 .pricing .row .col-sm-6:nth-child(2) {
        padding-left: 40px;
        padding-top: 70px;
        margin: 0.5cm auto;
        position: relative;
    }

    #homepagev2 .svg {
        transform: rotate(90deg);
        margin: auto;
        left: 50%;
        top: auto;
        bottom: 9px;
        margin: 0 0 0 -30px;
    }

    #homepagev2 .pricing .row .col-sm-6:nth-child(2) .svg {
        transform: rotate(270deg);
        margin: 9px 0 0 -30px;
        top: 0;
    }

    #homepagev2 .scale .col-md-6 {
        width: 100%;
        margin: 0 0 20px 0;
    }

    #homepagev2 .before-after {
        display: none;
    }

    #homepagev2 .pricing .row .col-sm-6:nth-child(1):before,
    #homepagev2 .pricing .row .col-sm-6:nth-child(2):before {
        position: absolute;
        left: 50%;
        top: 0;
        padding: 6px 10px;
        width: 90px;
        margin: 0 0 0 -45px;
        border-radius: 0 0 10px 10px;
        background: rgb(215 204 216);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
    }


    #homepagev2 .pricing .row .col-sm-6:nth-child(1):before {
        content: "Before";

    }

    #homepagev2 .pricing .row .col-sm-6:nth-child(2):before {
        content: "Now";
        color: #fff;
        background: rgb(98 0 195);
    }

}


/*pricing*/


#homepagev2 .faq .accordion-button {
    font-size: 1.2rem;
    font-weight: 600;
}

#homepagev2 .faq h2 {
    margin: 0px;
}

#homepagev2 .faq .accordion-button,
#homepagev2 .faq .accordion-body {
    padding: 1rem 1.5rem;
    background: transparent;
}



#homepagev2 .faq button:focus {
    outline: 0;
}

#homepagev2 .accordion-button:focus {
    box-shadow: none;
}

#homepagev2 .faq .accordion-button:not(.collapsed) {
    background: #c9afdf;
    background: linear-gradient(180deg, rgb(201 175 223 / 30%) 0%, rgb(212 204 221 / 30%) 100%);
    color: #000;
    outline: 0;
    border: 0;
}

#homepagev2 .faq .accordion-item {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: transparent;
}

#homepagev2 .faq .accordion-item:not(:first-of-type) {
    border-top: 1px solid #ddd;
}

/*button*/
#homepagev2 #button {
    display: flex;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s,
        opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    border: solid 1px #000;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 0;
    color: #000;
}

#homepagev2 #button:hover {
    cursor: pointer;
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    border-color: #fff;
    color: #fff;
}

#homepagev2 #button:active {
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    border-color: #fff;
    color: #fff;
}

#homepagev2 #button.show {
    opacity: 1;
    visibility: visible;
}

/*button*/

/*responsive start-----------------------------------*/
#homepagev2 .sub-text {
    font-size: 20px;
    margin-bottom: 50px;
}

#homepagev2 .mobile-none {
    display: block;
}

#homepagev2 .mobile-img {
    display: none;
}

@media (max-width: 992px) {
    #homepagev2 .mobile-img {
        display: block;
    }

    #homepagev2 .mobile-none {
        display: none;
    }
}





@media (max-width: 992px) {
    #homepagev2 body {}

    #homepagev2 h1 {
        font-size: 42px;
    }

    #homepagev2 h2 {
        font-size: 26px;
    }

    #homepagev2 h3 {
        font-size: 22px;
    }

    #homepagev2 h4 {
        font-size: 20px;
    }

    #homepagev2 .navbar-toggler {
        display: block;
        /* width: 40px; */
        height: 40px;
        display: inline-block;
        cursor: pointer;
        color: #fff;
        font-weight: normal;
        position: absolute;
        right: 10px;
        top: 25px;
        z-index: 25;
        opacity: 1;
    }

    #homepagev2 ul.nav {
        position: absolute;
        top: 70px;
        right: 21px;
        display: none;
        text-align: center;
        background: #0a0a0a;
        border: solid 2px #1b1c1c;
        width: auto;
        padding: 16px 0px;
        width: 260px;
    }

    #homepagev2 ul.nav.active {
        display: block;
    }

    #homepagev2 .nav-link {
        padding: 10px 10px;
        margin: 0;
        font-size: 20px;
    }

    #homepagev2 .apps {
        text-align: center;
    }

    @media(max-width: 768px) {
        #homepagev2 .pnavbar .btn {
            padding: 10px 20px;
            min-width: 90px;
        }
    }

    #homepagev2 .logo {}

    #homepagev2 header {
        height: 30px;
    }

    #homepagev2 .banner br {
        display: none;
    }

    #homepagev2 .section,
    #homepagev2 .section-2,
    #homepagev2 footer {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #homepagev2 footer {
        text-align: center;
        padding: 30px 0;
    }

    #homepagev2 footer .copy {
        margin: 19px 0;
    }

    #homepagev2 footer h3 {
        font-size: 37px;
        margin-bottom: 18px;
    }

    #homepagev2 .container {
        padding-left: 30px;
        padding-right: 30px;
    }


    #homepagev2 header.align-items-center {
        display: flex;
        justify-content: flex-start !IMPORTANT;
    }

    #homepagev2 .banner .buttons {
        margin-bottom: 30px;
        margin-top: 0;
    }

    #homepagev2 .pricing {
        max-width: 100%;
    }

    #homepagev2 .pricing svg {}

    #homepagev2 .scale .cont {
        background: transparent;
        padding: 0 10px;
    }

    #homepagev2 .scale .box {
        min-height: auto;
        border: solid 1px #c3c3c3;
    }

    #homepagev2 .pricing p br {
        display: none;
    }

    #homepagev2 .pricing h4 br {}





}

/*responsive end-----------------------------------*/


#homepagev2 .smarthire-hero {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(60deg, #ff3a7c, #741eff);
    -webkit-background-clip: text;
    background-clip: text;
    margin: 0;
}

#homepagev2 .smarthire-bg {
    enable-background: new 0 0 40 25;
}

#homepagev2 .auth-drop-down:after {
    content: "";
    background-image: inherit;
    width: inherit;
    height: inherit;
    position: inherit;
    left: inherit;
    top: inherit;
    border-radius: 5px;
    transition: inherit;
}